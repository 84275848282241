import { DYNAMIC, PORTAL_2 } from 'constants/constants';
import { isEmpty, isNil } from 'lodash';

export const getSearchAssessmentDru = (questionnaireId) => {
  const intent = '';
  const fields = ['AssessmentQuestionnaireId', 'QuestionId', 'QuestionText', 'IncludeVendorAssessment'];
  const criterion = [];

  const sort = [];
  const page = 0;
  const pageSize = 0;

  if (!isNil(questionnaireId)) {
    criterion.push({
      Field: 'AssessmentQuestionnaireId',
      Operator: '=',
      Value: questionnaireId,
    });
  }

  return {
    intent,
    fields,
    criterion,
    sort,
    page,
    pageSize,
  };
};

export const getAssessmentSecurityEvidenceRequirementListBody = (assessmentQuestionnaireId) => {
  let criterion = [];
  if (!isNil(assessmentQuestionnaireId)) {
    criterion.push({
      Field: 'AssessmentQuestionnaireId',
      Operator: '=',
      Value: assessmentQuestionnaireId,
    });
  }
  return {
    intent: '',
    fields: [
      'AssessmentId',
      'AssessmentQuestionnaireId',
      'RequirementId',
      'RequirementGroup',
      'RequirementNumber',
      'RequirementName',
      'RequirementType',
      'ControlNumber',
      'VendorProfileAssessment',
      'AssessmentQuestionnaireRequirementEvidenceList'
    ],
    criterion: criterion,
    sort: [],
    page: 0,
    pageSize: 0,
  };
};

export const updateFileDescriptionBody = (comment, evidenceId) => {
  return {
    VendorComments: comment,
    EvidenceId: evidenceId,
  };
};

export const searchAssessmentReviewSummaryQuestionsBody = (searchData = {}, onlyUnanswered = false) => {
  let criterion = [];
  if (!isEmpty(searchData)) {
    Object.keys(searchData).forEach((key) => {
      if (!isNil(searchData[key])) {
        criterion.push({
          Field: key,
          Operator: '=',
          Value: searchData[key],
        });
      }
    });
  }

  if(onlyUnanswered){
    criterion.push({
      Field: 'QuestionResponseModeId',
      Operator: 'hasnovalue',
      Value: '',
    });
  }

  return {
    intent: '',
    fields: ['AssessmentQuestionnaireId', 'QuestionId', 'QuestionText'],
    criterion: criterion,
    sort: [
      {
        Field: 'DisplayOrder',
        Order: 'ASC',
      },
    ],
    page: 0,
    pageSize: 0,
  };
};

export const generateCopilotResponseBody = (assessmentQuestionnaireId, questionList) => {
  return {
    "AssessmentQuestionnaireId": assessmentQuestionnaireId,
    "QuestionList": questionList,
  };
};

export const generateDRUResponseBody = (sourceAssessmentQuestionnaireId, targetAssessmentQuestionnaireId, portal) => {

  let body;
  if (portal === PORTAL_2) {
    body = {
      "SourceAssessmentQuestionnaireId": sourceAssessmentQuestionnaireId,
      "TargetAssessmentQuestionnaireId": targetAssessmentQuestionnaireId
    }
  } else if (portal === DYNAMIC) {
    body = {
      "SourceAssessmentQuestionnaireId": sourceAssessmentQuestionnaireId,
      "TargetLegacyAssessmentId": targetAssessmentQuestionnaireId
    }
  }

  return body;
};

export const generateCopilotWithDRUResponseBody = (sourceAssessmentQuestionnaireId, targetAssessmentQuestionnaireId, questionList, portal, productVendorId) => {

  let body;
  if (portal === PORTAL_2) {
    body = {
      "SourceAssessmentQuestionnaireId": sourceAssessmentQuestionnaireId,
      "TargetAssessmentQuestionnaireId": targetAssessmentQuestionnaireId,
      "QuestionList": questionList,
      "ProductVendorId": productVendorId ?? 0,
    }
  } else if (portal === DYNAMIC) {
    body = {
      "SourceAssessmentQuestionnaireId": sourceAssessmentQuestionnaireId,
      "TargetLegacyAssessmentId": targetAssessmentQuestionnaireId,
      "QuestionList": questionList,
      "ProductVendorId": productVendorId ?? 0,
    }
  }

  return body;
};

export const searchAssesmentReviewSummaryRequirementsBody = (assessmentQuestionnaireId) => {
  let criterion = [];

  if (!isNil(assessmentQuestionnaireId)) {
    criterion.push({
      Field: 'AssessmentQuestionnaireId',
      Operator: '=',
      Value: assessmentQuestionnaireId,
    });
  }

  return {
    intent: '',
    fields: [
      'AssessmentId',
      'AssessmentQuestionnaireId',
      'RequirementId',
      'RequirementGroup',
      'RequirementNumber',
      'RequirementName',
      'RequirementType',
      'ControlNumber',
      'CountOfParentQuestions',
      'CountOfParentQuestionAnswers',
      'AssessmentQuestionnaireQuestionResponseList',
      'AssessmentId',
      'TotalCountOfQuestionsAndAnswers',
    ],
    criterion: criterion,
    sort: [],
    page: 0,
    pageSize: 0,
  };
};
export const SaveAIFeedbackBody = () => {
  let criterion = [];
  return {
    intent: '',
    fields: [
      'AssessmentQuestionnaireQuestionResponseId',
      'UserId',
      'FeedbackTypeId',
    ],
    criterion: criterion,
    sort: [],
    page: 0,
    pageSize: 0,
  };
};

export const searchGeneratedResponseStatusBody = (assessmentQuestionnaireId) => {
  let criterion = [];

  if (!isNil(assessmentQuestionnaireId)) {
    criterion.push({
      "Field": 'AssessmentQuestionnaireId',
      "Operator": '=',
      "Value": assessmentQuestionnaireId,
    });
  }

  return {
    "Intent": "",
    "Fields": [
      "AssessmentId",
      "ParentQuestionId",
      "AssessmentQuestionnaireId",
      "AiGeneratedResponseText",
      "DruResponseText",
      "TotalCountOfCoPilotGeneratedQuestionsAndAnswers",
    ],
    "Criterion": criterion,
    "Sort": [],
    "Page": 0,
    "PageSize": 0,
  };
};

export const searchGeneratedCompanionResponseStatusBody = (assessmentId, assessmentQuestionnaireId) => {
  let criterion = [];

  if (!isNil(assessmentId)) {
    criterion.push({
      "Field": 'AssessmentId',
      "Operator": '=',
      "Value": assessmentId,
    });
  }

  if (!isNil(assessmentQuestionnaireId)) {
    criterion.push({
      "Field": 'AssessmentQuestionnaireId',
      "Operator": '=',
      "Value": assessmentQuestionnaireId,
    });
  }

  return {
    "Intent": '',
    "Fields": [
      "AssessmentId",
      "ParentQuestionId",
      "AssessmentQuestionnaireId",
      "AiGeneratedResponseText",
      "DruResponseText",
      "TotalCountOfCoPilotGeneratedQuestionsAndAnswers",
    ],
    "Criterion": criterion,
    "Sort": [],
    "Page": 0,
    "PageSize": 0,
  };
};

export const generateRequirementResponseBody = (searchData, questionList, productVendorId, documentIdList) => {
  return {
    "AssessmentQuestionnaireId": searchData?.AssessmentQuestionnaireId,
    "AssessmentQuestionnaireRequirementId": searchData?.AssessmentQuestionnaireRequirementId,
    "RequirementId": searchData?.RequirementId,
    "QuestionList": questionList,
    "ProductVendorId": productVendorId ?? 0,
    "DocumentIdList": documentIdList,
  };
};
export const generateRequirementGroupResponseBody = (searchData, questionList, productVendorId, documentIdList) => {
  return {
    "AssessmentQuestionnaireId": searchData?.AssessmentQuestionnaireId,
    "RequirementGroupId": searchData?.RequirementGroupId,
    "QuestionList": questionList,
    "ProductVendorId": productVendorId ?? 0,
    "DocumentIdList": documentIdList,
  };
};

export const generateCCRequirementResponseBody = (searchData, questionList, assessmentId, productVendorId) => {
  return {
    "AssessmentId": assessmentId,
    "AssessmentQuestionnaireRequirementId": searchData?.AssessmentQuestionnaireRequirementId,
    "RequirementId": searchData?.RequirementId,
    "QuestionList": questionList,
    "ProductVendorId": productVendorId ?? 0,
  };
};
export const generateCCRequirementGroupResponseBody = (searchData, questionList, assessmentId, productVendorId) => {
  return {
    "AssessmentId": assessmentId,
    "RequirementGroupId": searchData?.RequirementGroupId,
    "QuestionList": questionList,
    "ProductVendorId": productVendorId ?? 0,
  };
};
import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

function createGradient(color1, color2) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

// SETUP COLORS
const GREY = {
  0: '#FFFFFF',
  100: '#FBFAF8',
  200: '#F4F6F8',
  300: '#D8DEE4',
  400: '#EDF4F9',
  500: '#919EAB',
  600: '#637381',
  700: '#727476',
  800: '#212B36',
  900: '#161C24',
  500_8: alpha('#919EAB', 0.08),
  500_12: alpha('#919EAB', 0.12),
  500_16: alpha('#919EAB', 0.16),
  500_24: alpha('#919EAB', 0.24),
  500_32: alpha('#919EAB', 0.32),
  500_48: alpha('#919EAB', 0.48),
  500_56: alpha('#919EAB', 0.56),
  500_80: alpha('#919EAB', 0.8),
};

const TEAL = {
  200: '#E8F4F5',
  600: '#5BB3BC',
  700: '#109AA9',
}

const ORANGE = {
  600: '#F5B335',
}

//Orange
const PRIMARY = {
  lighter: '#FAF5ED',
  light: '#F5B335',
  main: '#F78D2A',
  dark: '#F5B335',
  darker: '#F5B335',
  contrastText: '#091B2A',
};
//NavyBlue
const SECONDARY = {
  lighter: '#EDF4F9',
  light: '#145091',
  main: '#182A53',
  dark: '#145091',
  darker: '#145091',
  contrastText: '#fff',
};
//SkyBlue
const INFO = {
  lighter: '#9EC9EB',
  light: '#74CAFF',
  main: '#0083E9',
  dark: '#0C53B7',
  darker: '#04297A',
  contrastText: '#fff',
};
//Green
const SUCCESS = {
  lighter: '#EEF6F0',
  light: '#51BB69',
  medium: '#B3E2BE',
  main: '#2C9543',
  dark: '#51BB69',
  darker: '#51BB69',
  contrastText: '#fff',
};
//Yellow
const WARNING = {
  lighter: '#FFF7CD',
  light: '#FFE16A',
  main: '#FFC107',
  dark: '#B78103',
  darker: '#7A4F01',
  contrastText: GREY[800],
};
//Red
const ERROR = {
  lighter: '#F5EEEE',
  light: '#CB3737',
  main: '#9D2727',
  dark: '#CB3737',
  darker: '#852C2C',
  contrastText: '#fff',
};
//Beige
const TOOLTIP_COLOR = {
  main: '#FFE6B3',
};
//Executive summary
const EXECUTIVE_SUMMARY = {
  grey: '#A1A5AA',
  main: '#F5B335',
  info: '#0083E9',
  success: '#789B4A',
  contrastText: '#fff',
  error: '#B52C2C',
};

const FOOTER_COLOR = {
  light: '#D8DEE4',
  main: '#B3BCC5',
};

//Swagger Colors
const SWAGGER = {
  GET: '#61affe',
  POST: '#49cc90',
  PUT: '#49cc90',
  PATCH: '#50e3c2',
  DELETE: '#f93e3e',
  GET_BG_COLOR: '#61affe1a',
  POST_BG_COLOR: '#49cc901a',
  PUT_BG_COLOR: '#49cc901a',
  PATCH_BG_COLOR: '#50e3c21a',
  DELETE_BG_COLOR: '#f93e3e1a',
  REQUIRED: '#ff000099',
};

const GRADIENTS = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main),
  secondary: createGradient(SECONDARY.light, SECONDARY.main),
};

const palette = {
  common: { black: '#000', white: '#fff' },
  primary: { ...PRIMARY },
  secondary: { ...SECONDARY },
  info: { ...INFO },
  success: { ...SUCCESS },
  warning: { ...WARNING },
  error: { ...ERROR },
  tooltip: { ...TOOLTIP_COLOR },
  footer: { ...FOOTER_COLOR },
  grey: GREY,
  orange: ORANGE,
  teal: TEAL,
  gradients: GRADIENTS,
  divider: GREY[500_24],
  executiveSummary: { ...EXECUTIVE_SUMMARY },
  swagger: { ...SWAGGER },
  text: { primary: GREY[800], secondary: GREY[600], disabled: GREY[500], default: '#54575A', placeholder: '#999' },
  background: { paper: '#fff', default: '#fff', neutral: GREY[200] },
  action: {
    active: GREY[600],
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

export default palette;

import axios from 'axios';
import {
  SaveAIFeedbackBody,
  generateCCRequirementGroupResponseBody,
  generateCCRequirementResponseBody,
  generateCopilotResponseBody,
  generateCopilotWithDRUResponseBody,
  generateDRUResponseBody,
  generateRequirementGroupResponseBody,
  generateRequirementResponseBody,
  getAssessmentSecurityEvidenceRequirementListBody,
  getSearchAssessmentDru,
  searchAssessmentReviewSummaryQuestionsBody,
  searchGeneratedCompanionResponseStatusBody,
  searchGeneratedResponseStatusBody,
  updateFileDescriptionBody,
} from 'utils/assessmentCoPilotUtils';
import { setErrors } from './errorHandler';
import { NO_DATA_FOUND } from 'constants/errorMessage';
import { getErrorObject } from 'utils/apiUtils';
import { SELECTION_TYPE_COPILOT, SELECTION_TYPE_COPILOT_PLUS_DRU, SELECTION_TYPE_DRU } from 'pages/AssessmentDetails/AssessmentCoPilot/ReviewSummary/constants';
import { getAssessmentQuestionnaireRequirement, getAssessmentQuestionnaireRequirementGroup } from './assessmentQuestionnaire';
import { GENERATE_COPILOT_RESPONSE_TIMEOUT, NO_VALUE, TRUE, YES_VALUE } from 'constants/constants';
import { searchAssessmentOverviewPortalAssessmentDetails } from './assessmentsOverview';
import { isNil } from 'lodash';
import { searchOrganisationalSecurityEvidenceRequirementList, searchProductSecurityEvidenceRequirementList, searchProductSpecificRequirementSummaryQuestionList } from './vendorCopilot';
import { ASSESSMENT_SPECIFIC_UPLOAD_EVIDENCE_PAGE_TYPE, ORGANISATIONAL_SPECIFIC_UPLOAD_EVIDENCE_PAGE_TYPE, PRODUCT_SPECIFIC_UPLOAD_EVIDENCE_PAGE_TYPE } from 'pages/Products/VendorCoPilot/constants';

export const SEARCH_ASSESSMENT_DRU_LOADING = '@assessmentCoPilot/SEARCH_ASSESSMENT_DRU_LOADING';
export const SEARCH_ASSESSMENT_DRU_SUCCESS = '@assessmentCoPilot/SEARCH_ASSESSMENT_DRU_SUCCESS';
export const SEARCH_ASSESSMENT_DRU_FAILURE = '@assessmentCoPilot/SEARCH_ASSESSMENT_DRU_FAILURE';

export const searchAssessmentDru =
  (questionnaireId, callback = null) =>
    async (dispatch) => {
      const body = getSearchAssessmentDru(questionnaireId);
      dispatch({
        type: SEARCH_ASSESSMENT_DRU_LOADING,
      });

      try {
        const res = await axios.post('/assessment/dru/search', body);
        if (res?.data?.ErrorCount > 0) {
          dispatch({
            type: SEARCH_ASSESSMENT_DRU_FAILURE,
          });

          const errors = res?.data?.Errors?.filter((errorItem) => {
            return errorItem.Message !== NO_DATA_FOUND;
          });

          dispatch(setErrors(errors));


          if (callback) {
            callback(true);
          }
        } else {
          dispatch({
            type: SEARCH_ASSESSMENT_DRU_SUCCESS,
            payload: res?.data?.Data?.Rows,
          });

          if (callback) {
            callback(false);
          }
        }
      } catch (error) {
        dispatch({
          type: SEARCH_ASSESSMENT_DRU_FAILURE,
        });
      }
    };

export const SEARCH_ASSESSMENT_SECURITY_EVIDENCE_REQUIREMENT_LIST_LOADING = '@assessmentCoPilot/SEARCH_ASSESSMENT_SECURITY_EVIDENCE_REQUIREMENT_LIST_LOADING';
export const SEARCH_ASSESSMENT_SECURITY_EVIDENCE_REQUIREMENT_LIST_SUCCESS = '@assessmentCoPilot/SEARCH_ASSESSMENT_SECURITY_EVIDENCE_REQUIREMENT_LIST_SUCCESS';
export const SEARCH_ASSESSMENT_SECURITY_EVIDENCE_REQUIREMENT_LIST_FAILURE = '@assessmentCoPilot/SEARCH_ASSESSMENT_SECURITY_EVIDENCE_REQUIREMENT_LIST_FAILURE';

export const searchAssessmentSecurityEvidenceRequirementList =
  (assessmentQuestionnaireId = null) =>
    async (dispatch) => {
      dispatch({ type: SEARCH_ASSESSMENT_SECURITY_EVIDENCE_REQUIREMENT_LIST_LOADING });
      try {
        const body = getAssessmentSecurityEvidenceRequirementListBody(assessmentQuestionnaireId);
        const response = await axios.post(`/assessment/questionnaire/requirement/search`, body);
        if (response?.data?.ErrorCount > 0) {
          dispatch({
            type: SEARCH_ASSESSMENT_SECURITY_EVIDENCE_REQUIREMENT_LIST_FAILURE,
            payload: [],
          });
        } else {
          dispatch({
            type: SEARCH_ASSESSMENT_SECURITY_EVIDENCE_REQUIREMENT_LIST_SUCCESS,
            payload: response.data || [],
          });
        }
      } catch (err) {
        dispatch({
          type: SEARCH_ASSESSMENT_SECURITY_EVIDENCE_REQUIREMENT_LIST_FAILURE,
          payload: getErrorObject(err),
        });
      }
    };

export const DELETE_ASSESSMENT_SECURITY_EVIDENCE_REQUIREMENT_FILE_LOADING = '@assessmentCoPilot/DELETE_ASSESSMENT_SECURITY_EVIDENCE_REQUIREMENT_FILE_LOADING';
export const DELETE_ASSESSMENT_SECURITY_EVIDENCE_REQUIREMENT_FILE_SUCCESS = '@assessmentCoPilot/DELETE_ASSESSMENT_SECURITY_EVIDENCE_REQUIREMENT_FILE_SUCCESS';
export const DELETE_ASSESSMENT_SECURITY_EVIDENCE_REQUIREMENT_FILE_FAILURE = '@assessmentCoPilot/DELETE_ASSESSMENT_SECURITY_EVIDENCE_REQUIREMENT_FILE_FAILURE';

export const deleteAssessmentSecurityEvidenceRequirementFile =
  (assessmentId, assessmentQuestionnaireRequirementEvidenceIdList, assessmentQuestionnaireId) => async (dispatch) => {
    dispatch({ type: DELETE_ASSESSMENT_SECURITY_EVIDENCE_REQUIREMENT_FILE_LOADING });
    try {
      const response = await axios.delete(`assessment/${assessmentId}/questionnaire/requirement/evidencelist`, {
        data: assessmentQuestionnaireRequirementEvidenceIdList,
      });
      if (response?.data?.ErrorCount > 0) {
        dispatch({
          type: DELETE_ASSESSMENT_SECURITY_EVIDENCE_REQUIREMENT_FILE_FAILURE,
          payload: [],
        });
      } else {
        dispatch({
          type: DELETE_ASSESSMENT_SECURITY_EVIDENCE_REQUIREMENT_FILE_SUCCESS,
          payload: response.data || [],
        });
        dispatch(searchAssessmentSecurityEvidenceRequirementList(assessmentQuestionnaireId));
      }
    } catch (err) {
      dispatch({
        type: DELETE_ASSESSMENT_SECURITY_EVIDENCE_REQUIREMENT_FILE_FAILURE,
        payload: getErrorObject(err),
      });
    }
  };

export const UPDATE_ASSESSMENT_SECURITY_EVIDENCE_REQUIREMENT_FILE_DESCRIPTION_LOADING =
  '@assessmentCoPilot/UPDATE_ASSESSMENT_SECURITY_EVIDENCE_REQUIREMENT_FILE_DESCRIPTION_LOADING';
export const UPDATE_ASSESSMENT_SECURITY_EVIDENCE_REQUIREMENT_FILE_DESCRIPTION_SUCCESS =
  '@assessmentCoPilot/UPDATE_ASSESSMENT_SECURITY_EVIDENCE_REQUIREMENT_FILE_DESCRIPTION_SUCCESS';
export const UPDATE_ASSESSMENT_SECURITY_EVIDENCE_REQUIREMENT_FILE_DESCRIPTION_FAILURE =
  '@assessmentCoPilot/UPDATE_ASSESSMENT_SECURITY_EVIDENCE_REQUIREMENT_FILE_DESCRIPTION_FAILURE';

export const updateAssessmentSecurityEvidenceRequirementFileDescription = (comment, evidenceId, assessmentQuestionnaireId) => async (dispatch) => {
  dispatch({ type: UPDATE_ASSESSMENT_SECURITY_EVIDENCE_REQUIREMENT_FILE_DESCRIPTION_LOADING });
  try {
    const body = updateFileDescriptionBody(comment, evidenceId);
    const response = await axios.post(`/assessment/evidence/${evidenceId}/action/comment`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: UPDATE_ASSESSMENT_SECURITY_EVIDENCE_REQUIREMENT_FILE_DESCRIPTION_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: UPDATE_ASSESSMENT_SECURITY_EVIDENCE_REQUIREMENT_FILE_DESCRIPTION_SUCCESS,
        payload: response.data || [],
      });
      dispatch(searchAssessmentSecurityEvidenceRequirementList(assessmentQuestionnaireId));
    }
  } catch (err) {
    dispatch({
      type: UPDATE_ASSESSMENT_SECURITY_EVIDENCE_REQUIREMENT_FILE_DESCRIPTION_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SEARCH_ASSESSMENT_REVIEW_SUMMARY_QUESTIONS_LOADING = '@assessmentCoPilot/SEARCH_ASSESSMENT_REVIEW_SUMMARY_QUESTIONS_LOADING';
export const SEARCH_ASSESSMENT_REVIEW_SUMMARY_QUESTIONS_SUCCESS = '@assessmentCoPilot/SEARCH_ASSESSMENT_REVIEW_SUMMARY_QUESTIONS_SUCCESS';
export const SEARCH_ASSESSMENT_REVIEW_SUMMARY_QUESTIONS_FAILURE = '@assessmentCoPilot/SEARCH_ASSESSMENT_REVIEW_SUMMARY_QUESTIONS_FAILURE';

export const searchAssessmentReviewSummaryQuestions = (assessmentQuestionnaireId, callback, isResetCopilot = false) => async (dispatch) => {
  dispatch({ type: SEARCH_ASSESSMENT_REVIEW_SUMMARY_QUESTIONS_LOADING });
  try {
    const body = searchAssessmentReviewSummaryQuestionsBody(undefined, isResetCopilot);
    const response = await axios.post(`assessment/questionnaire/${assessmentQuestionnaireId}/response/search`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: SEARCH_ASSESSMENT_REVIEW_SUMMARY_QUESTIONS_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: SEARCH_ASSESSMENT_REVIEW_SUMMARY_QUESTIONS_SUCCESS,
        payload: response.data || [],
      });
      if (callback) {
        callback(response?.data?.Data?.Rows);
      }
    }
    const orgPath = `${location.pathname}?${searchParams.toString()}`;
    searchParams.delete('isCopilotReset');
    const replacePath = `${location.pathname}?${searchParams.toString()}`;
    history.replace(orgPath, replacePath);
  } catch (err) {
    dispatch({
      type: SEARCH_ASSESSMENT_REVIEW_SUMMARY_QUESTIONS_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const GENERATE_ASSESSMENT_REVIEW_SUMMARY_RESPONSES_LOADING = '@vendorCopilot/GENERATE_ASSESSMENT_REVIEW_SUMMARY_RESPONSES_LOADING';
export const GENERATE_ASSESSMENT_REVIEW_SUMMARY_RESPONSES_SUCCESS = '@vendorCopilot/GENERATE_ASSESSMENT_REVIEW_SUMMARY_RESPONSES_SUCCESS';
export const GENERATE_ASSESSMENT_REVIEW_SUMMARY_RESPONSES_FAILURE = '@vendorCopilot/GENERATE_ASSESSMENT_REVIEW_SUMMARY_RESPONSES_FAILURE';

export const generateAssessmentReviewSummaryResponse =
  (assessmentId = null, 
    assessmentQuestionnaireId = null, 
    targetAssessmentQuestionnaireId = null, 
    portal,
    questionList = {},
    type, 
    isRequirementGroup = NO_VALUE, 
    props = {}, 
    productVendorId = 0,
    callback) =>
    async (dispatch) => {
      dispatch({ type: GENERATE_ASSESSMENT_REVIEW_SUMMARY_RESPONSES_LOADING });

      let url;
      let body;
      if (type === SELECTION_TYPE_DRU) {
        url = `assessment/${assessmentId}/action/generatedru`;
        body = generateDRUResponseBody(assessmentQuestionnaireId, targetAssessmentQuestionnaireId, portal);
      } else if (type === SELECTION_TYPE_COPILOT_PLUS_DRU) {
        url = `assessment/${assessmentId}/action/generatedruwithcopilot`;
        body = generateCopilotWithDRUResponseBody(assessmentQuestionnaireId, targetAssessmentQuestionnaireId, questionList, portal, productVendorId);
      }

      try {
        const response = await axios.post(url, body, {
          timeout: 300000,
        });
        if (response?.data?.ErrorCount > 0) {
          dispatch({
            type: GENERATE_ASSESSMENT_REVIEW_SUMMARY_RESPONSES_FAILURE,
            payload: [],
          });
        } else {
          dispatch({
            type: GENERATE_ASSESSMENT_REVIEW_SUMMARY_RESPONSES_SUCCESS,
            payload: response.data || [],
          });

          if (isRequirementGroup === YES_VALUE) {
            dispatch(getAssessmentQuestionnaireRequirementGroup(assessmentQuestionnaireId));
          } else {
            dispatch(getAssessmentQuestionnaireRequirement(assessmentQuestionnaireId));
          }

          dispatch(searchProductSpecificRequirementSummaryQuestionList(assessmentId, null, assessmentQuestionnaireId))

          if (!isNil(props?.parentAssessmentId) && !isNil(props?.assessmentRequestId)) {
            dispatch(searchAssessmentOverviewPortalAssessmentDetails(props?.parentAssessmentId, props?.assessmentRequestId, assessmentQuestionnaireId));
          }

          if (callback) {
            callback();
          }
        }
      } catch (err) {
        dispatch({
          type: GENERATE_ASSESSMENT_REVIEW_SUMMARY_RESPONSES_FAILURE,
          payload: getErrorObject(err),
        });
      }
    };

export const SEARCH_ASSESSMENT_REVIEW_REQUIREMENT_LIST_LOADING = '@assessmentCoPilot/SEARCH_ASSESSMENT_REVIEW_REQUIREMENT_LIST_LOADING';
export const SEARCH_ASSESSMENT_REVIEW_REQUIREMENT_LIST_SUCCESS = '@assessmentCoPilot/SEARCH_ASSESSMENT_REVIEW_REQUIREMENT_LIST_SUCCESS';
export const SEARCH_ASSESSMENT_REVIEW_REQUIREMENT_LIST_FAILURE = '@assessmentCoPilot/SEARCH_ASSESSMENT_REVIEW_REQUIREMENT_LIST_FAILURE';

export const searchAssessmentReviewRequirementList = (assessmentQuestionnaireId) => async (dispatch) => {
  dispatch({ type: SEARCH_ASSESSMENT_REVIEW_REQUIREMENT_LIST_LOADING });
  try {
    // keep below code as backup in case of any issue
    // const body = searchAssesmentReviewSummaryRequirementsBody(assessmentQuestionnaireId);
    const response = await axios.get(`assessment-questionnaires/${assessmentQuestionnaireId}/requirements/responses`);

    dispatch({
      type: SEARCH_ASSESSMENT_REVIEW_REQUIREMENT_LIST_SUCCESS,
      payload: response.data?.Data || [],
    });
  } catch (err) {
    dispatch({
      type: SEARCH_ASSESSMENT_REVIEW_REQUIREMENT_LIST_FAILURE,
      payload: getErrorPayload(err),
    });
  }
};

export const SET_REVIEW_SUMMARY_TAB_VALUE = '@vendorCopilot/SET_REVIEW_SUMMARY_TAB_VALUE';

export const setReviewSummaryTabValue =
  (value = REVIEW_SUMMARY_TAB_OPTIONS.ALL) =>
    async (dispatch) => {
      dispatch({
        type: SET_REVIEW_SUMMARY_TAB_VALUE,
        payload: value,
      });
    };

export const SAVE_ASSESSMENT_QUESTIONNAIRE_RESPONSE_AI_FEEDBACK_LOADING = '@assessmentCoPilot/SAVE_ASSESSMENT_QUESTIONNAIRE_RESPONSE_AI_FEEDBACKT_LOADING';
export const SAVE_ASSESSMENT_QUESTIONNAIRE_RESPONSE_AI_FEEDBACK_SUCCESS = '@assessmentCoPilot/SAVE_ASSESSMENT_QUESTIONNAIRE_RESPONSE_AI_FEEDBACK_SUCCESS';
export const SAVE_ASSESSMENT_QUESTIONNAIRE_RESPONSE_AI_FEEDBACK_FAILURE = '@assessmentCoPilot/SAVE_ASSESSMENT_QUESTIONNAIRE_RESPONSE_AI_FEEDBACK_FAILURE';

export const AddAIFeedback = (formData) => async (dispatch) => {
  const { AssessmentQuestionnaireQuestionResponseId, UserId, FeedbackTypeId, Comment, assessmentId } = formData;
  dispatch({ type: SAVE_ASSESSMENT_QUESTIONNAIRE_RESPONSE_AI_FEEDBACK_LOADING });
  try {
    const body = {
      AssessmentQuestionnaireQuestionResponseId: AssessmentQuestionnaireQuestionResponseId,
      UserId: UserId,
      FeedbackTypeId: FeedbackTypeId,
      Comment: Comment,
    };
    const response = await axios.post(`assessment/${assessmentId}/questionnaireresponse/${AssessmentQuestionnaireQuestionResponseId}/action/feedback`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: SAVE_ASSESSMENT_QUESTIONNAIRE_RESPONSE_AI_FEEDBACK_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: SAVE_ASSESSMENT_QUESTIONNAIRE_RESPONSE_AI_FEEDBACK_SUCCESS,
        payload: response.data.Data || [],
      });
    }
  } catch (err) {
    dispatch({
      type: SAVE_ASSESSMENT_QUESTIONNAIRE_RESPONSE_AI_FEEDBACK_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SEARCH_REVIEW_RESPONSE_PROGRESS_STATUS_LOADING = '@assessmentCoPilot/SEARCH_REVIEW_RESPONSE_PROGRESS_STATUS_LOADING';
export const SEARCH_REVIEW_RESPONSE_PROGRESS_STATUS_SUCCESS = '@assessmentCoPilot/SEARCH_REVIEW_RESPONSE_PROGRESS_STATUS_SUCCESS';
export const SEARCH_REVIEW_RESPONSE_PROGRESS_STATUS_FAILURE = '@assessmentCoPilot/SEARCH_REVIEW_RESPONSE_PROGRESS_STATUS_FAILURE';

export const searchReviewProgressStatus = (assessmentId, assessmentQuestionnaireId, callback) => async (dispatch) => {
  dispatch({ type: SEARCH_REVIEW_RESPONSE_PROGRESS_STATUS_LOADING });
  try {
    let body = searchGeneratedResponseStatusBody(assessmentQuestionnaireId);
    const response = await axios.post(`/assessment/${assessmentId}/generatedresponse/progressstatus/search`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: SEARCH_REVIEW_RESPONSE_PROGRESS_STATUS_FAILURE,
        payload: [],
      });
    } else {
      if (callback) {
        const totalCountOfAIGeneratedQuestion = response.data?.Data?.Rows[0]?.TotalCountOfAiGeneratedQuestion;
        const totalCountOfDRUGeneratedQuestion = response.data?.Data?.Rows[0]?.TotalCountOfDRUGeneratedQuestion;
        let isResponseGenerated = ( totalCountOfAIGeneratedQuestion > 0 || totalCountOfDRUGeneratedQuestion > 0 );
        callback(isResponseGenerated, response.data?.Data?.Rows[0]);
      }
      dispatch({
        type: SEARCH_REVIEW_RESPONSE_PROGRESS_STATUS_SUCCESS,
        payload: response.data || [],
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_REVIEW_RESPONSE_PROGRESS_STATUS_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SEARCH_COMPANION_RESPONSE_PROGRESS_STATUS_LOADING = '@vendorCopilot/SEARCH_COMPANION_RESPONSE_PROGRESS_STATUS_LOADING';
export const SEARCH_COMPANION_RESPONSE_PROGRESS_STATUS_SUCCESS = '@vendorCopilot/SEARCH_COMPANION_RESPONSE_PROGRESS_STATUS_SUCCESS';
export const SEARCH_COMPANION_RESPONSE_PROGRESS_STATUS_FAILURE = '@vendorCopilot/SEARCH_COMPANION_RESPONSE_PROGRESS_STATUS_FAILURE';

export const searchCompanionProgressStatus = (assessmentId, callback, assessmentQuestionnaireId) => async (dispatch) => {
  dispatch({ type: SEARCH_COMPANION_RESPONSE_PROGRESS_STATUS_LOADING });
  try {
    let body = searchGeneratedCompanionResponseStatusBody(assessmentId, assessmentQuestionnaireId);
    const response = await axios.post(`/assessment/${assessmentId}/generatedresponse/progressstatus/search`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: SEARCH_COMPANION_RESPONSE_PROGRESS_STATUS_FAILURE,
        payload: [],
      });
    } else {
      if (callback) {
        const totalCountOfAIGeneratedQuestion = response.data?.Data?.Rows[0]?.TotalCountOfAiGeneratedQuestion;
        const totalCountOfDRUGeneratedQuestion = response.data?.Data?.Rows[0]?.TotalCountOfDRUGeneratedQuestion;
        let isResponseGenerated = ( totalCountOfAIGeneratedQuestion > 0 || totalCountOfDRUGeneratedQuestion > 0 );
        callback(isResponseGenerated);
      }
      dispatch({
        type: SEARCH_COMPANION_RESPONSE_PROGRESS_STATUS_SUCCESS,
        payload: response.data || [],
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_COMPANION_RESPONSE_PROGRESS_STATUS_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SEARCH_COMPANION_RESPONSE_PROGRESS_ASSESSMENT_STATUS_LOADING = '@vendorCopilot/SEARCH_COMPANION_RESPONSE_PROGRESS_ASSESSMENT_STATUS_LOADING';
export const SEARCH_COMPANION_RESPONSE_PROGRESS_ASSESSMENT_STATUS_SUCCESS = '@vendorCopilot/SEARCH_COMPANION_RESPONSE_PROGRESS_ASSESSMENT_STATUS_SUCCESS';
export const SEARCH_COMPANION_RESPONSE_PROGRESS_ASSESSMENT_STATUS_FAILURE = '@vendorCopilot/SEARCH_COMPANION_RESPONSE_PROGRESS_ASSESSMENT_STATUS_FAILURE';

export const searchCompanionProgressStatusOfAssessment = (assessmentId, assessmentQuestionnaireId, callback) => async (dispatch) => {
  dispatch({ type: SEARCH_COMPANION_RESPONSE_PROGRESS_ASSESSMENT_STATUS_LOADING });
  try {
    let body = searchGeneratedCompanionResponseStatusBody(assessmentId, assessmentQuestionnaireId);
    const response = await axios.post(`/assessment/${assessmentId}/generatedresponse/progressstatus/search`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: SEARCH_COMPANION_RESPONSE_PROGRESS_ASSESSMENT_STATUS_FAILURE,
        payload: [],
      });
    } else {
      if (callback) {
        const totalCountOfAIGeneratedQuestion = response.data?.Data?.Rows[0]?.TotalCountOfAiGeneratedQuestion;
        const totalCountOfDRUGeneratedQuestion = response.data?.Data?.Rows[0]?.TotalCountOfDRUGeneratedQuestion;
        let isResponseGenerated = ( totalCountOfAIGeneratedQuestion > 0 || totalCountOfDRUGeneratedQuestion > 0 );
        callback(isResponseGenerated);
      }
      dispatch({
        type: SEARCH_COMPANION_RESPONSE_PROGRESS_ASSESSMENT_STATUS_SUCCESS,
        payload: response.data || [],
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_COMPANION_RESPONSE_PROGRESS_ASSESSMENT_STATUS_FAILURE,
      payload: getErrorObject(err),
    });
  }
};



/// Dynamic Loader for Each card
// You will have async for loop to call each api by id
// We will use this id to use as a loader.
// to that, we can store this in an array.
// When api finised (success / fail) we can remove that id from array.

// at component level for each card, we will use if array contains an id and then show loader.

export const SEARCH_REQUIREMENT_QUESTIONS_LOADING = '@assessmentCoPilot/SEARCH_REQUIREMENT_QUESTIONS_LOADING';
export const SEARCH_REQUIREMENT_QUESTIONS_SUCCESS = '@assessmentCoPilot/SEARCH_REQUIREMENT_QUESTIONS_SUCCESS';
export const SEARCH_REQUIREMENT_QUESTIONS_FAILURE = '@assessmentCoPilot/SEARCH_REQUIREMENT_QUESTIONS_FAILURE';
export const SEARCH_DATA_ONLY_UNANSWERED_QUESTIONS = "onlyUnanswered";

export const searchRequirementQuestions = (assessmentId, searchData = {}, callback) => async (dispatch) => {
  dispatch({ type: SEARCH_REQUIREMENT_QUESTIONS_LOADING });
  try {
    const onlyUnanswered = searchData[SEARCH_DATA_ONLY_UNANSWERED_QUESTIONS] || false;
    // exclude SEARCH_DATA_ONLY_UNANSWERED_QUESTIONS
    const {[SEARCH_DATA_ONLY_UNANSWERED_QUESTIONS]: _, ...filteredSearchData} = searchData;

    const body = searchAssessmentReviewSummaryQuestionsBody(filteredSearchData, onlyUnanswered);
    const response = await axios.post(`assessment/${assessmentId}/questionnaire/response/search`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: SEARCH_REQUIREMENT_QUESTIONS_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: SEARCH_REQUIREMENT_QUESTIONS_SUCCESS,
        payload: response.data || [],
      });
      if (callback) {
        callback(response?.data?.Data?.Rows);
      }
    }
  } catch (err) {
    dispatch({
      type: SEARCH_REQUIREMENT_QUESTIONS_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const GENERATE_REQUIREMENT_RESPONSES_LOADING = '@vendorCopilot/GENERATE_REQUIREMENT_RESPONSES_LOADING';
export const GENERATE_REQUIREMENT_RESPONSES_SUCCESS = '@vendorCopilot/GENERATE_REQUIREMENT_RESPONSES_SUCCESS';
export const GENERATE_REQUIREMENT_RESPONSES_FAILURE = '@vendorCopilot/GENERATE_REQUIREMENT_RESPONSES_FAILURE';
export const REQUIREMENT_GENERATE_IN_PROGRESS = '@vendorCopilot/REQUIREMENT_GENERATE_IN_PROGRESS';

export const generateRequirementResponse =
  (searchData = {}, questionList = {}, questionnaireId, productVendorId = 0, callback) =>
    async (dispatch, getState) => {
      const isRequirementGroup = searchData?.isRequirementGroup === YES_VALUE;

      dispatch({
        type: GENERATE_REQUIREMENT_RESPONSES_LOADING,
        payload: isRequirementGroup ? searchData?.RequirementGroupId : searchData?.RequirementId,
      });

      let body;

      // Get selected document list in the store and pass it to the generate response API
      // Use in case generating response for companion assessment
      const selectedDocumentList = getState().assessmentQuestionnaire.selectedDocumentList;
      const documentIdList = selectedDocumentList?.map((evidence) => {
        const fileType = evidence.DocumentName?.split('.');
        return `${evidence.UUID}.${fileType[fileType.length - 1]}`;
      });

      if (isRequirementGroup) {
        body = generateRequirementGroupResponseBody(searchData, questionList, productVendorId, documentIdList);
      } else {
        body = generateRequirementResponseBody(searchData, questionList, productVendorId, documentIdList);
      }

      try {
        const response = await axios.post(`assessment/${searchData?.AssessmentId}/questionnaire/${searchData?.AssessmentQuestionnaireId}/action/generateresponse`, body, {
          timeout: GENERATE_COPILOT_RESPONSE_TIMEOUT,
        });

        if (response?.data?.ErrorCount > 0) {
          dispatch({
            type: GENERATE_REQUIREMENT_RESPONSES_FAILURE,
            payload: isRequirementGroup ? searchData?.RequirementGroupId : searchData?.RequirementId,
          });
        } else {
          callback?.();
          dispatch(checkGeneratedResponseStatus({ questionnaireId }, questionnaireId, isRequirementGroup));
        }
      } catch (err) {
        dispatch(checkGeneratedResponseStatus({ questionnaireId }, questionnaireId, isRequirementGroup));
      }
    };


  const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

  // Define a global tracker
  const activeCallsTracker = {};
  // Helper function to generate a unique key based on parameters
  const generateCallKey = (questionaireId, isRequirementGroup) => `questionaireId-${questionaireId}-isRequirementGroup-${isRequirementGroup}`;
  
  /**
   * 
   * @param {*} paramCheckProgress an object that contains the assessmentId or questionaireId
   * @param {*} questionaireId questionId
   * @param {boolean} isRequirementGroup indicates if the requirement is a group
   */
  const checkGeneratedResponseStatus = (paramCheckProgress, questionaireId, isRequirementGroup) => async (dispatch) => {
    const callKey = generateCallKey(questionaireId, isRequirementGroup);

    if (activeCallsTracker[callKey]) {
      console.log(`A checkGeneratedResponseStatus call with questionaireId ${questionaireId} is already in progress.`);
      return; // Exit if a similar call is in progress
    }

    let updateQuestionaireRequirement = isRequirementGroup ? getAssessmentQuestionnaireRequirementGroup : getAssessmentQuestionnaireRequirement;

    activeCallsTracker[callKey] = true;
  
    let keepTrying = true;
    let MAX_RETRY = 200;
    let totalTries = 0;

    try {
      while(keepTrying && totalTries < MAX_RETRY) {
        // call the api to check which requirements are still in progress
        const params = [];
        let apiUrl = `/assessment/incomplete-ai-progress`;
        if (paramCheckProgress['questionnaireId']) params.push(`questionnaireId=${paramCheckProgress['questionnaireId']}`);
        if (paramCheckProgress['assessmentId']) params.push(`assessmentId=${paramCheckProgress['assessmentId']}`);
        if (params.length) apiUrl += `?${params.join('&')}`;
  
        const response = await axios.get(apiUrl);
  
        // update number of response generated for each requirement in the questionaire 
        await dispatch(updateQuestionaireRequirement(questionaireId));

        const progressDetails = response?.data?.Data?.Rows;
        if (response?.data?.ErrorCount > 0 || progressDetails.length === 0) {
          keepTrying = false;

          dispatch({
            type: REQUIREMENT_GENERATE_IN_PROGRESS,
            payload: []
          });
        }
        
        if (progressDetails?.length > 0) {
          const incompleteIds = progressDetails.map(detail => 
            isRequirementGroup ? detail.RequirementGroupId :detail.RequirementId
          );

          dispatch({
            type: REQUIREMENT_GENERATE_IN_PROGRESS,
            payload: incompleteIds
          });
        }

        totalTries++;

         // generating response by AI take time, so instead of continous calling
         // sleep for a few seconds to reduce workload for server
         keepTrying && sleep(2000);
      }

      if (totalTries >= MAX_RETRY) {
        // if reaches max retry, then stop loading
        dispatch({
          type: REQUIREMENT_GENERATE_IN_PROGRESS,
          payload: []
        });

        dispatch(updateQuestionaireRequirement(questionaireId));
      }
    } catch (error) {
      dispatch({
        type: REQUIREMENT_GENERATE_IN_PROGRESS,
        payload: []
      });

      dispatch(updateQuestionaireRequirement(questionaireId));
    } finally {
      // Clear the call from the tracker once completed or failed
      delete activeCallsTracker[callKey];
    }
  }

  export const GENERATE_CC_REQUIREMENT_RESPONSES_LOADING = '@vendorCopilot/GENERATE_CC_REQUIREMENT_RESPONSES_LOADING';
  export const GENERATE_CC_REQUIREMENT_RESPONSES_SUCCESS = '@vendorCopilot/GENERATE_CC_REQUIREMENT_RESPONSES_SUCCESS';
  export const GENERATE_CC_REQUIREMENT_RESPONSES_FAILURE = '@vendorCopilot/GENERATE_CC_REQUIREMENT_RESPONSES_FAILURE';
  
  export const generateCCRequirementResponse =
    (searchData = {}, questionList = {}, assessmentId, questionnaireId, productVendorId = 0, callback) =>
    async (dispatch) => {
      const isRequirementGroup = searchData?.isRequirementGroup === YES_VALUE;

      dispatch({ type: GENERATE_CC_REQUIREMENT_RESPONSES_LOADING,
        payload: isRequirementGroup ? searchData?.RequirementGroupId : searchData?.RequirementId,
      });
  
      let body;

      if (isRequirementGroup) {
        body = generateCCRequirementGroupResponseBody(searchData, questionList, assessmentId, productVendorId);
      } else {
        body = generateCCRequirementResponseBody(searchData, questionList, assessmentId, productVendorId);
      }
  
      try {
        const response = await axios.post(`assessment/${assessmentId}/action/generateresponse`, body);

        if (response?.data?.ErrorCount > 0) {
          dispatch({
            type: GENERATE_CC_REQUIREMENT_RESPONSES_FAILURE,
            payload: isRequirementGroup ? searchData?.RequirementGroupId : searchData?.RequirementId,
          });
        } else {
          callback?.();
          
          dispatch(checkGeneratedResponseStatus({ assessmentId }, questionnaireId, isRequirementGroup));
        }
        
      } catch (err) {
        
        dispatch(checkGeneratedResponseStatus({ assessmentId }, questionnaireId, searchData?.isRequirementGroup === YES_VALUE));
      }
    };
// Upload Evidence Files

export const CO_PILOT_UPLOAD_EVIDENCE_LOADING = '@@assessmentCoPilot/CO_PILOT_UPLOAD_EVIDENCE_LOADING';
export const CO_PILOT_UPLOAD_EVIDENCE_SUCCESS = '@@assessmentCoPilot/CO_PILOT_UPLOAD_EVIDENCE_SUCCESS';
export const CO_PILOT_UPLOAD_EVIDENCE_FAILURE = '@@assessmentCoPilot/CO_PILOT_UPLOAD_EVIDENCE_FAILURE';

export const uploadAssessmentCoPilotEvidenceFiles = (formData, assessmentId, type, assessmentQuestionnaireId) => async (dispatch) => {
  dispatch({
    type: CO_PILOT_UPLOAD_EVIDENCE_LOADING,
  });

  let tempFormData = {
    ...formData,
  };
  let attachEvidenceList = [];

  delete tempFormData['UploadedFile'];

  let body = new FormData();

  Object.keys(tempFormData).forEach((key) => {
    body.append(key, JSON.stringify(tempFormData[key]));
  });

  formData?.UploadedFile.forEach((item) => {
    if (!isNil(item?.EvidenceId)) {
      attachEvidenceList.push({ EvidenceId: item?.EvidenceId, FileName: item?.FileName });
    } else {
      body.append(`UploadedFileList`, item?.UploadedFile);
    }
  });

  if (attachEvidenceList?.length > 0) {
    body.append(`EvidenceList`, JSON.stringify(attachEvidenceList));
  }

  try {
    const response = await axios.post(`/assessment/questionnaire/requirement/evidence`, body, {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    });

    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: CO_PILOT_UPLOAD_EVIDENCE_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: CO_PILOT_UPLOAD_EVIDENCE_SUCCESS,
        payload: response?.data?.Data,
      });
      if (type === ORGANISATIONAL_SPECIFIC_UPLOAD_EVIDENCE_PAGE_TYPE) {
        dispatch(searchOrganisationalSecurityEvidenceRequirementList(assessmentId));
      } else if (type === PRODUCT_SPECIFIC_UPLOAD_EVIDENCE_PAGE_TYPE) {
        dispatch(searchProductSecurityEvidenceRequirementList(assessmentId));
      } else if (type === ASSESSMENT_SPECIFIC_UPLOAD_EVIDENCE_PAGE_TYPE) {
        dispatch(searchAssessmentSecurityEvidenceRequirementList(assessmentQuestionnaireId));
      }
    }
  } catch (err) {
    dispatch({
      type: CO_PILOT_UPLOAD_EVIDENCE_FAILURE,
      payload: getErrorObject(err),
    });
  }
};
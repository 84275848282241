import React, { Suspense, lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Navigate, useRoutes } from 'react-router-dom';

import ProtectedRoute from './auth/ProtectedRoute';
import { Loading } from './components';

const Incident = lazy(() => import(/* webpackChunkName: "Incident" */ './pages/Incident/Incident'));

const IncidentCampaign = lazy(() => import(/* webpackChunkName: "IncidentCampaign" */ './pages/IncidentCampaign/IncidentCampaign'));
const Dashboard = lazy(() => import(/* webpackChunkName: "Dashboard" */ './pages/Dashboard/Dashboard'));
const Assessment = lazy(() => import(/* webpackChunkName: "Assessment" */ './pages/Assessment/Assessment'));
const Profile = lazy(() => import(/* webpackChunkName: "profile" */ './views/profile'));
const ExternalApi = lazy(() => import(/* webpackChunkName: "externalApi" */ './views/external-api'));
const Session = lazy(() => import(/* webpackChunkName: "Session" */ './pages/Session/Session'));
const Logout = lazy(() => import(/* webpackChunkName: "Logout" */ './pages/Logout/Logout'));
const AnswerQuestionnaire = lazy(() => import(/* webpackChunkName: "AnswerQuestionnaire" */ './pages/AnswerQuestionnaire/AnswerQuestionnaire'));
const VendorProfile = lazy(() => import(/* webpackChunkName: "VendorProfile" */ './pages/VendorProfile/VendorProfile'));
const QuestionnaireList = lazy(() => import('./pages/Questionnaire/QuestionnaireList'));
const Unauthorized = lazy(() => import(/* webpackChunkName: "Unauthorized" */ './pages/Unauthorized/Unauthorized'));
const Error = lazy(() => import(/* webpackChunkName: "Error" */ './pages/Error/Error'));
const SupportTicket = lazy(() => import(/* webpackChunkName: "SupportTicket" */ './pages/SupportTicket/SupportTicketOverview'));
const DeveloperPortal = lazy(() => import(/* webpackChunkName: "DeveloperPortal" */ './pages/SupportTicket/DeveloperPortal/DeveloperPortal'));
const SupportTicketDetails = lazy(() => import(/* webpackChunkName: "SupportTicketDetails" */ './pages/SupportTicket/SupportTicketDetails'));

const SupportArticles = lazy(() => import(/* webpackChunkName: "SupportArticles" */ './pages/SupportTicket/SupportArticles'));
const Products = lazy(() => import(/* webpackChunkName: "Products" */ './pages/Products'));
const EvidenceManagement = lazy(() => import(/* webpackChunkName: "EvidenceManagement" */ './pages/Products/EvidenceManagement/EvidenceManagement'));

const AssessmentResults = lazy(() => import(/* webpackChunkName: "AssessmentResults" */ './pages/AssessmentResults'));

const HitrustRDSConfiguration = lazy(() => import(/* webpackChunkName: "HitrustRDSConfiguration" */ './pages/HitrustRDSConfiguration/HitrustRDSConfiguration'));

const ProductDetails = lazy(() => import(/* webpackChunkName: "ProductDetails" */ './pages/ProductDetails/ProductDetails'));

const ProductRequirements = lazy(() =>
  import(/* webpackChunkName: "ProductDetails" */ './pages/ProductDetails/ProductRequirements/ProductRequirementsDetails')
);

const AssessmentResponses = lazy(() => import(/* webpackChunkName: "AssessmentResponses" */ './pages/AssessmentResponses'));

const CommunicationPreferences = lazy(() => import(/* webpackChunkName: "CommunicationPreferences*/ 'pages/CommunicationPreferences'));

const ProductPreferences = lazy(() => import(/* webpackChunkName: "ProductPreferences*/ 'pages/ProductPreferences'));

const ProductProfileWizard = lazy(() => import(/*webpackChunkName: "ProductProfileWizard"*/ './pages/Products/ProductProfileWizard/ProductProfileWizard'));

const AssessmentsOverview = lazy(() => import(/*webpackChunkName: "ProductProfileWizard"*/ './pages/AssessmentsOverview'));

const AssessmentQuestionnaire = lazy(() => import(/*webpackChunkName: "ProductProfileWizard"*/ './pages/AssessmentQuestionnaire'));

const AssessmentDetails = lazy(() => import(/* webpackChunkName: "AssessmentDetails*/ 'pages/AssessmentDetails/Layout'));

const Remediation = lazy(() => import(/* webpackChunkName: "Remediation" */ './pages/Remediation'));

const VendorOnboardingWizard = lazy(() => import(/* webpackChunkName: "VendorOnboardingWizard" */ './pages/VendorOnboarding'));

const RemediationObjective = lazy(() => import(/* webpackChunkName: "RemediationObjective" */ './pages/Remediation/RemediationObjective'));

const VendorCoPilot = lazy(() => import(/* webpackChunkName: "VendorCoPilot" */ './pages/Products/VendorCoPilot'));

const AssessmentCoPilot = lazy(() => import(/* webpackChunkName: "VendorCoPilot" */ './pages/AssessmentDetails/AssessmentCoPilot'));

const CompanionAssessment = lazy(() => import('./pages/CompanionAssessment/CompanionAssessment'));

const CreateCompanionAssessment = lazy(() => import('./pages/CreateCompanionAssessment/CreateCompanionAssessment'));

export default function Router() {
  return (
    <Suspense fallback={<Loading />}>
      <ProtectedRoute path="/" exact component={Dashboard} />
      <ProtectedRoute path="/incident-campaign" exact component={IncidentCampaign} />
      <ProtectedRoute path="/dashboard" exact component={Dashboard} />
      <ProtectedRoute path="/assessment" exact component={Assessment} />
      <ProtectedRoute path="/profile" component={Profile} />
      <ProtectedRoute path="/vendor-profile" component={VendorProfile} />
      <ProtectedRoute path="/external-api" component={ExternalApi} />
      <ProtectedRoute path="/incident" exact component={QuestionnaireList} />
      <ProtectedRoute path="/answer-questionnaire/:id/:assessmentId" exact component={AnswerQuestionnaire} />
      <Route path="/session" component={Session} />
      <Route path="/logout" component={Logout} />
      <Route path="/unauthorized" component={Unauthorized} />
      <Route path="/error" component={Error} />
      <ProtectedRoute path="/support-articles" exact component={SupportArticles} />
      <ProtectedRoute path="/support" exact component={SupportTicket} />
      <ProtectedRoute path="/support-ticket-details/:id" exact component={SupportTicketDetails} />
      <ProtectedRoute path="/developer-portal" exact component={DeveloperPortal} />
      <ProtectedRoute path="/products" exact component={Products} />
      <ProtectedRoute path="/product/:id" exact component={ProductDetails} />
      <ProtectedRoute path="/evidence-management" exact component={EvidenceManagement} />
      <ProtectedRoute path="/product/:productvendorid/assessment-results/:id" exact component={AssessmentResults} />
      <ProtectedRoute path="/assessment-results/:id" exact component={AssessmentResults} />
      <ProtectedRoute path="/product/:productvendorid/requirement/:requirementId" exact component={ProductRequirements} />
      <ProtectedRoute path="/hitrust-rds-configuration" exact component={HitrustRDSConfiguration} />
      <ProtectedRoute path="/product/:productvendorid/assessment-results/:id/assessment-responses" exact component={AssessmentResponses} />
      <ProtectedRoute path="/assessment-results/:id/assessment-responses" exact component={AssessmentResponses} />
      <ProtectedRoute path="/communication-preferences" exact component={CommunicationPreferences} />
      <ProtectedRoute path="/product-preferences" exact component={ProductPreferences} />
      <ProtectedRoute path="/products/product-profile-wizard/" exact component={ProductProfileWizard} />
      <ProtectedRoute path="/assessments-overview" exact component={AssessmentsOverview} />
      <ProtectedRoute
        path="/assessment/:assessmentId/assessments-overview/:parentAssessmentId/assessment-details/:assessmentRequestId/questionnaire/:questionnaireId"
        exact
        component={AssessmentQuestionnaire}
      />
      <ProtectedRoute
        path="/assessment/:assessmentId/assessments-overview/:parentAssessmentId/assessment-details/:assessmentRequestId"
        exact
        component={AssessmentDetails}
      />
      <ProtectedRoute path="/assessment/:assessmentId/assessments-overview/assessment-details" exact component={AssessmentDetails} />
      <ProtectedRoute path="/vendor-onboarding-wizard" exact component={VendorOnboardingWizard} />
      <Route path="/executive-summary" exact component={AssessmentResults} />
      <ProtectedRoute path="/remediation" exact component={Remediation} />
      <ProtectedRoute path="/remediation/remediation-objective/:id" exact component={RemediationObjective} />
      <ProtectedRoute path="/vendor-copilot" exact component={VendorCoPilot} />
      <ProtectedRoute path="/vendor-copilot/:parentAssessmentId/:questionnaireId" exact component={VendorCoPilot} />
      <ProtectedRoute path="/assessment-copilot" exact component={AssessmentCoPilot} />
      <ProtectedRoute path="/companion-assessment" exact component={CompanionAssessment} />
      <ProtectedRoute path="/create-companion-assessment" exact component={CreateCompanionAssessment} />
    </Suspense>
  );
}

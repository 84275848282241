import { Box, Breadcrumbs, Button, Grid, Link, Typography } from '@mui/material';
import Seperator from '../../assets/icons/breadcrumbs-seperator.svg';

import { bannerContainerWrap, bannerTitleStyles, breadcrumbLabel, buttonsGrid, breadCrumbs } from './bannerStyle';
import { useHistory } from 'react-router-dom';

const Banner = ({ title, buttonLabel, breadcrumbs, onClickButton, icon }) => {
  const history = useHistory();
  const onClickBreadCrumb = (url) => {
    history.push(url);
  };

  return (
    <>
      <Box sx={bannerContainerWrap}>
        <Grid container>
          <Grid item xs={8}>
            {breadcrumbs && (
              <Breadcrumbs id="breadcrumbs" aria-label="breadcrumb" sx={breadCrumbs} separator={<img alt="" src={Seperator} />}>
                {breadcrumbs &&
                  breadcrumbs.map((breadcrumb, i) => {
                    return (
                      <Link component={'button'} key={i} underline="hover" color="inherit" onClick={() => onClickBreadCrumb(breadcrumb.url)} sx={breadcrumbLabel}>
                        {breadcrumb.text}
                      </Link>
                    );
                  })}
              </Breadcrumbs>
            )}
            {title && (
              <Typography variant="display3" sx={(theme) => bannerTitleStyles(theme)}>
                {title}
              </Typography>
            )}
          </Grid>

          <Grid item xs={4} sx={buttonsGrid}>
            {buttonLabel && (
              <Button variant="contained" onClick={onClickButton} startIcon={icon}>
                <Typography variant="caption">{buttonLabel}</Typography>
              </Button>
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Banner;
